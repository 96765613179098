<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      :visible="showSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="30%"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            마이스토어 수정
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <div>
            <b-row>
              <b-col>
                <b-form-group label="마이스토어명">
                  <b-form-input
                    v-model="newName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-form-group label="소개글">
                  <b-form-input
                    v-model="newIntroText"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="instagram URL">
                  <b-form-input
                    v-model="instagramUrl"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="블로그 URL">
                  <b-form-input
                    v-model="blogUrl"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="youtube URL">
                  <b-form-input
                    v-model="youtubeUrl"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="썸네일 URL">
                  <b-form-input
                    v-model="newProfileImgUrl"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-img
                v-if="newProfileImgUrl"
                :src="newProfileImgUrl"
                width="120"
                height="120"
                class="m-1"
              />
            </b-row>
          </div>

          <div>
            <b-row>
              <b-col>
                <input
                  style="display:none;"
                  @change="fetchUploadImg" accept="image/*" type="file" id="file"
                >
                <b-button
                  class="mb-1"
                  variant="primary"
                  style="cursor: default;"
                  block
                >
                  <label for="file" style="height: 100%; color: white; cursor:pointer;">
                    썸네일 바꾸기
                  </label>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="onSave"
                >
                  저장
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSidebar,
  BFormGroup,
  BFormInput,
  BImg,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import store from '@/store'
import uploadFile from '@/hooks/uploadFile'

export default defineComponent({
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BImg,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
    mystoreIdx: {
      type: [Number, String],
    },
    mystoreData: {
      type: Object,
    },
  },
  setup(props, context) {
    const toast = useToast()
    const instagramUrl = ref()
    const blogUrl = ref()
    const youtubeUrl = ref()
    const newName = ref(props.mystoreData.name)
    const newIntroText = ref(props.mystoreData.intro_text)
    const newProfileImgUrl = ref(props.mystoreData.profile_img_url)
    onMounted(() => {
      const snsList = JSON.parse(props.mystoreData.sns_list_json)
      instagramUrl.value = snsList.INSTA
      blogUrl.value = snsList.NAVER_BLOG
      youtubeUrl.value = snsList.YOUTUBE
    })
    
    const onHidden = () => {
      context.emit('on-hidden')
    }
    const onShown = () => {}

    const fetchUploadImg = async e => {
      const imgFile = e.target.files[0]
      const file_obj = await uploadFile(imgFile, 'mystore_info')
      newProfileImgUrl.value = file_obj.url
    }

    const onSave = () => {
      store
        .dispatch('app-invoice/updateMystore', {
          idx: props.mystoreIdx,
          name: newName.value,
          intro_text: newIntroText.value,
          profile_img_url: newProfileImgUrl.value,
          sns_list_json: JSON.stringify({
            INSTA: instagramUrl.value,
            NAVER_BLOG: blogUrl.value,
            YOUTUBE: youtubeUrl.value,
          }),
        })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '마이스토어 수정에 성공했습니다',
              icon: 'BellIcon',
              variant: 'primary',
            },
          })
          context.emit('refresh-detail')
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '마이스토어 수정에 실패했습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {
      onHidden,
      onShown,
      onSave,
      fetchUploadImg,
      newName,
      newIntroText,
      newProfileImgUrl,
      instagramUrl,
      blogUrl,
      youtubeUrl,
    }
  },
})
</script>

<style>

</style>