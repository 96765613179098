import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCancelAmount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/order/cancel-amount/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrderState(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/order/change-state/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/list/`, { params: queryParams },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderGroup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/order-group/`, { params: queryParams },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSelectedCancelPrice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/cancel-price/`, { params: queryParams },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSelectedTakebackPrice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/takeback-price/`, { params: queryParams },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDelivery(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/update-delivery/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/cancel/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    takebackOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/takeback/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paidOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/paid/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paymentOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/payment/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    trackingNoOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/tracking-no/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    doneOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/done/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    canceledOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/canceled/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    chargeOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/charge/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchShipmentCompanyList() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/info/shipment_companies/`,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderMemos(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/memo/`, { params: queryParams },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downOrderExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/order/download/adm/`,
            params,
            { responseType: 'blob' },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendOrderExcel(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/send-order-excel/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/tagby/order/view/${idx}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMystoreList(ctx, filters) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/myshop/?page=${filters.page}`,
            filters,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMystore(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/${data.idx}/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    settlementMargin(ctx, data) {
      console.log(data)
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/settle-order/inf/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/list/`, { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getSettlementSummary(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/order/summary/`,
            data,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getOrderDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/order/${idx}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrderDashboard() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/order/dashboard/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    resetPassword(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/reset-password/`,
            params,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetDelivery(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/reset-delivery/`,
            params,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateShopper(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/update-shopper/`,
            params,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    revertCanceledOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/order/revert-canceled-order/`,
            params,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
