<template>
  <b-row v-if="mystoreData">
    <b-col
      cols="12"
      sm="9"
      md="9"
      lg="10"
      xl="10"
      class="myshop-detail"
    >
      <b-card title="Mystore Detail">
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="마이스토어명"
              label-cols="3"
            >
              <b-form-input
                v-model="mystoreData.name"
                readonly
                style="opacity: 100%;"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="URL"
              label-cols="3"
            >
              <b-input-group>
                <b-form-input
                  id="url-path"
                  style="color: #258BFF; opacity: 1; cursor: pointer;"
                  readonly
                  :value="mystoreUrl"
                  @click="openMystoreTab"
                />
                <b-input-group-append
                  v-clipboard:copy="mystoreUrl"
                  is-text
                  class="cursor-pointer"
                  @click="makeCopyToast(mystoreUrl)"
                >
                  <feather-icon
                    icon="CopyIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="담긴 상품 갯수"
              label-cols="3"
            >
              <b-form-input
                v-model="productList.length"
                style="opacity: 1;"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="인플루언서 IDX"
              label-cols="3"
            >
              <b-form-input
                v-model="mystoreData.inf_idx_id"
                readonly
                style="color: #258BFF; opacity: 1; cursor: pointer;"
                @click="toInfDetail"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-card
          v-for="product, i in productList"
          :key="i"
          no-body
          class="product-card ml-2"
          :bg-variant="product.state === 'INACTIVE' ? 'dark' : ''"
        >
          <b-card-img
            class="mb-1"
            style="height: 150px;"
            :src="product.thumbnail_img_url"
            alt="썸네일이 없습니다."
          />
          <b-card-text
            class="text-center"
          >
            <b-badge
              v-if="product.state === 'INACTIVE'"
              class="w-50"
              variant="dark"
            >
              - 마이스토어에서 뻰 상품입니다 -
            </b-badge>
            <b-badge
              v-else-if="product.sell_type === 'ALWAYS'"
              class="w-50"
              variant="light-info"
            >
              상시판매
            </b-badge>
            <b-badge
              v-else-if="product.sell_type === 'RECRUITMENT'"
              class="w-50"
              variant="danger"
            >
              모집판매
            </b-badge>
          </b-card-text>
          <b-card-text
            class="ml-1"
            style="overflow:hidden; text-overflow:ellipsis; white-space:nowrap"
          >
            상품명: {{ product.name }}
          </b-card-text>
          <b-card-text
            class="ml-1"
          >
            태그바이품번: {{ product.tagby_product_no }}
          </b-card-text>
          <b-card-text>
            <b-button
              v-b-modal.toggle-product
              variant="danger"
              class="w-50"
              @click="openRemoveModal(product)"
            >{{ product.state === 'ACTIVE' ? '상품 빼기' : '다시 담기' }}
            </b-button>
            <b-button
              variant="primary"
              class="w-50"
              @click="toDetail(product)"
            >상품 상세</b-button>
          </b-card-text>
        </b-card>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      sm="3"
      md="3"
      lg="2"
      xl="2"
    >
      <b-card class="action-button-box">
        <!-- modal UI start -->
        <b-modal
          id="toggle-product"
          @ok="toggleProductFromMystore"
        >
          {{ selectedDisplayProduct.state === 'ACTIVE' ?
            '해당 상품을 마이스토어에서 빼시겠습니까?' :
            '해당 상품을 마이스토어에 다시 담으시겠습니까?'
          }}
        </b-modal>
        <SearchModal
          :modalId="'select-product'"
          :searchCategoryList="searchCategoryList"
          :searchResult="searchResult"
          :searchResultColumns="searchResultColumns"
          :totalRows="totalRows"
          :isBusy="isBusy"
          @search="searchProduct"
          @ok="addProduct"
        />
        <!-- modal UI end -->
        <b-button
          v-b-modal.select-product
          variant="primary"
          block
        >
          상품 담기
        </b-button>
        <b-button
          variant="primary"
          block
          @click="toggleMystoreUpdateSidebar"
        >
          마이샵 정보 수정
        </b-button>
      </b-card>
    </b-col>

    <mystore-update-sidebar
      :show-sidebar="showMystoreUpdateSidebar"
      :mystore-idx="idx"
      :mystoreData="mystoreData"
      @on-hidden="onHiddenMystoreUpdateSidebar"
    />

  </b-row>
</template>

<script>
import { defineComponent, ref, onUnmounted } from '@vue/composition-api'
import {
  BCard, BButton, VBModal, BCol, BRow, BCardImg,
  BCardText, BBadge, BFormGroup, BFormInput,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

import SearchModal from './components/SearchModal.vue'
import MystoreUpdateSidebar from './components/MystoreUpdateSidebar.vue'
import invoiceStoreModule from './orderStoreModule'

export default defineComponent({
  props: {
    idx: [Number, String],
  },

  components: {
    BCard,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardImg,
    BCardText,
    BBadge,
    SearchModal,
    MystoreUpdateSidebar,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup(props) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
      }
    })
    const mystoreData = ref()
    const mystoreUrl = ref('')
    const productList = ref([])
    const selectedDisplayProduct = ref({})

    const fetchMystoreDetail = async () => {
      try {
        const result = await axios
          .get(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/view/${props.idx}/`,
          )
        mystoreData.value = result.data.data
        mystoreUrl.value = `${process.env.VUE_APP_MYSTORE_URL}/${mystoreData.value.url_path}`
        productList.value = result.data.data.product_list.sort((a, b) => a.state.localeCompare(b.state))
      } catch (e) {
        console.log('fail')
      }
    }
    fetchMystoreDetail()

    const searchCategoryList = [
      { text: '태그바이품번', value: 'tagby_product_no[]' },
      { text: '상품번호', value: 'product_no[]' },
      { text: '상품명', value: 'product_name[]' },
    ]
    const searchResult = ref([])
    const searchResultColumns = [
      {
        key: 'idx',
        label: 'idx',
      },
      {
        key: 'tagby_product_no',
        label: '태그바이품번',
      },
      {
        key: 'brand_name',
        label: '브랜드명',
      },
      {
        key: 'name',
        label: '상품명',
      },
      {
        key: 'select',
        label: '선택',
      },
    ]
    const totalRows = ref(0)
    const isBusy = ref(false)

    const searchProduct = queryParams => {
      isBusy.value = true
      store
        .dispatch('app-invoice/fetchProductList', queryParams)
        .then(response => {
          searchResult.value = response.data.data
          totalRows.value = response.data.page_info?.total_count
        }).catch(error => {
          alert(error.response.data)
        }).finally(() => {
          isBusy.value = false
        })
    }

    const addProduct = selectedProduct => {
      axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/add-product/`,
        { mystore_idx: parseInt(props.idx, 10), tagby_product_no: selectedProduct.tagby_product_no },
      )
        .then(() => {
          alert('상품 담기를 완료하였습니다.')
          router.go()
        }).catch(err => {
          console.log(err.response.data)
          alert('상품 담기를 실패하였습니다.')
        })
    }

    const updateMystore = () => {
      console.log('implemented yet')
    }

    const openRemoveModal = item => {
      selectedDisplayProduct.value = item
    }

    const closeRemoveModal = () => {
      selectedDisplayProduct.value = {}
    }

    const toggleProductFromMystore = () => {
      const body = {
        idx: selectedDisplayProduct.value.idx,
      }
      axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/toggle-product/`,
        body,
      ).then(() => {
        router.go()
      }).catch(() => {
        alert('변경에 실패했습니다.')
      })
    }

    const toDetail = item => {
      router.push({ name: 'commerce-product-detail', params: { idx: item.product_idx_id } })
    }

    const toInfDetail = event => {
      router.push({ name: 'member-inf-detail', params: { idx: event.target.value } })
    }

    const openMystoreTab = event => {
      window.open(event.target.value, '_blank')
    }

    const toast = useToast()
    const makeCopyToast = text => {
      if (text !== null && text !== '') {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Copy Success',
            icon: 'BellIcon',
            text,
            variant: 'primary',
          },
        })
      }
    }

    // mystore update sidebar
    const showMystoreUpdateSidebar = ref(false)
    const toggleMystoreUpdateSidebar = () => {
      showMystoreUpdateSidebar.value = !showMystoreUpdateSidebar.value
    }
    const onHiddenMystoreUpdateSidebar = () => {
      showMystoreUpdateSidebar.value = false
    }

    return {
      mystoreUrl,
      mystoreData,
      productList,
      selectedDisplayProduct,
      updateMystore,
      openRemoveModal,
      closeRemoveModal,
      toggleProductFromMystore,
      toDetail,
      toInfDetail,
      openMystoreTab,
      makeCopyToast,

      // modal
      searchCategoryList,
      searchResult,
      searchResultColumns,
      totalRows,
      isBusy,
      searchProduct,
      addProduct,

      // update sidebar
      showMystoreUpdateSidebar,
      toggleMystoreUpdateSidebar,
      onHiddenMystoreUpdateSidebar,
    }
  },
})

</script>

<style lang="scss">
.product-card {
  width: 30%;
}
</style>
